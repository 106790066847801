@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Saira+Semi+Condensed&display=swap');
@import "colors.scss";
@import "init.scss";
@import "settings.scss";
@import "inputs.scss";
@import "grid.scss";
@import "flex.scss";
@import "functions.scss";
@import "nav.scss";

body {
    font-family: 'Open Sans', sans-serif;
}
h1, h2, h3, h4, h5, h6 {
    margin:10px 0px;
    font-family: 'Saira Semi Condensed', sans-serif;
}
h1 {
    color:map-get($colors, "primary");

    @media screen and (max-width:600px) {
        font-size: 1.7em;
    }
}
h2 {
    color:map-get($colors, "secondary");

    @media screen and (max-width:600px) {
        font-size: 1.3em;
    }
}
h3 {
    color:map-get($colors, "secondary-lighter");

    @media screen and (max-width:600px) {
        font-size: 1em;
    }
}
h4 {
    color:map-get($colors, "mid-grey");
}
p {
    margin:15px 0px;
}
.container {
    max-width:1200px;
    padding:15px;
    text-align: justify;
    margin:auto;
    overflow:hidden;
}
.they-said {
    min-height:150px;
    border-top:1px solid map-get($colors, "primary");
    border-bottom:1px solid map-get($colors, "primary");

    h2 {
        color:map-get($colors, "primary");
    }
}
.tech-logo {
    min-height:296px;
    img {
        width:80px;
    }
}
footer {
    height:30px;
    line-height: 30px;
    text-align: center;
    background-color:map-get($colors, "dark-grey");
    color:white;
}
.contact-line {
    height:30px;
    line-height:30px;
    color:white;
    text-align:center;
    display:flex;
    justify-content: center;
}
%img {
    img {
        width:100%;
        object-fit: cover;
    }

    @media screen and (max-width:600px) {
        margin-bottom:15px!important;
    }
}
.img-borderless {
    @extend %img;
}
.img-bordered {
    @extend %img;
    border:2px solid map-get($colors, "dark-grey");
}
.message-box {
    background-color:rgba(255,255,255,0.9);
    position:fixed;
    margin:auto;
    border:1px solid map-get($colors, "light-grey");
    box-shadow: 0px 0px 4px black;
    display:none;
    z-index:1000;
}
.message-header {
    background-color:map-get($colors, "primary");
    color:white;
    height:30px;
    line-height:30px;
    text-align:right;
    cursor: pointer;
}
.button-holder {
    bottom:10px;
}
@media screen and (max-width:600px) {
    .left, .right {
        float:none;
        margin:auto;
    }
    .contact-line {
        font-size:13px;
    }
}


.message-box {
    width:300px;
    height:300px;
    background-color:rgba(255,255,255,0.8);
    border:1px solid #dddddd;
    position:fixed;
    box-shadow: 0px 0px 4px #343434;
}

.message-box-header {
    background-color:#495f76;
    height:30px;
    cursor:pointer;
    text-align: right;
    color:white;
    padding:5px;
}

.message-box-body {
    height:200px;
    display:grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding:10px;
    overflow: auto;
}

.message-box-buttons {
    display:flex;
    align-items: center;
    justify-content: space-evenly;
}

.clear {
    clear:both;
    height:10px;
}

.list {
    list-style-type: none;
    list-style-position: outside;
    padding-left:15px;
}

.list li {
    margin-bottom:10px;
}

.box-white {
    background-color:white;
    border:1px solid map-get($colors, "light-grey");
}

a {
    color:map-get($colors, "primary-lighter");
}

.none-underline {
    text-decoration: none;
}

.bold {
    font-weight: bold;
}

.no-bullet-list {
    list-style: none;
    list-style-position: inside;
}