@mixin createGrid($from, $to, $size, $isImportant:false, $is100Percent:false) {
    @for $i from $from to $to+1 {
        .col-#{$size}-#{$i} {
            @if($is100Percent) {
                @if($isImportant) {
                    width:100%!important;
                } @else {
                    width:100%;
                }
            } @else {
                @if($isImportant) {
                    width:calc(100%/12*#{$i})!important;
                } @else {
                    width:calc(100%/12*#{$i});
                }
            }
        }
    }
}

.row {
    display:flex;
    flex-wrap: wrap;
}

@include createGrid(1, 12, "lg", true);
@include createGrid(1, 12, "md", false);
@include createGrid(1, 12, "sm", false);
@include createGrid(1, 12, "xs", false);

@media screen and (max-width:1140px) {
    @include createGrid(1, 12, "lg", true, true);
    @include createGrid(1, 12, "md", true);
}

@media screen and (max-width:800px) {
    @include createGrid(1, 12, "md", true, true);
    @include createGrid(1, 12, "sm", true);
}

@media screen and (max-width:400px) {
    @include createGrid(1, 12, "sm", true, true);
    @include createGrid(1, 12, "xs", true);
}