@import "functions.scss";
@import "colors.scss";
/*
    padding
    margin
    colors
    bg-colors
    text-align
    font-sizes
    pozíciók
    clearfix
*/
$positions:static, relative, absolute, fixed, sticky;
$textAligns:left, center, right, justify;
@keyframes closeAnim {
    from {display:block;opacity: 1;}
    to {display:none;opacity: 0;}
}
@keyframes openAnim {
    from {display:none;opacity: 0;}
    to {display:block;opacity: 1;}
}
.box {
    background-color:map-get($colors, "v-light-grey");
    border:1px solid map-get($colors, "light-grey");
}
.center-tag {
    margin:auto;
}
.left {
    float:left;
}
.right {
    float:right;
}
.clear {
    width:100%;
    height:10px;
    clear:both;
}
.overflow {
    overflow:auto;
}
.display-none {
    display:none;
}
.display-none-anim {
    animation-name: closeAnim;
    animation-duration: 0.6s;
    display:none!important;
}
.display-block-anim {
    animation-name: openAnim;
    animation-duration: 0.6s;
    display:block!important;
}

@include loopProperties(1, 220, "max-width", "mw", 5, px);
@include loopProperties(1, 220, "max-height", "mh", 5, px);
@include loopProperties(1, 220, "min-height", "mih", 5, px);
@include loopProperties(1, 220, "min-width", "miw", 5, px);
@include loopProperties(1, 20, "width", "w", 5, '%');

@include listProperties($positions, "position", "pos");
@include listProperties($textAligns, "text-align", "text");

@include mapProperties($colors, "color", "color");
@include mapProperties($colors, "background-color", "bg");

@include loopProperties(1, 50, "font-size", "font", 1);

@include loopProperties(1, 20, "padding", "p", 5);
@include loopProperties(1, 20, "padding-left", "pl", 5);
@include loopProperties(1, 20, "padding-right", "pr", 5);
@include loopProperties(1, 20, "padding-top", "pt", 5);
@include loopProperties(1, 20, "padding-bottom", "pb", 5);

@include loopProperties(1, 20, "margin", "m", 5);
@include loopProperties(1, 20, "margin-left", "ml", 5);
@include loopProperties(1, 20, "margin-right", "mr", 5);
@include loopProperties(1, 20, "margin-top", "mt", 5);
@include loopProperties(1, 20, "margin-bottom", "mb", 5);

.mobile-center {
    @media screen and (max-width:600px) {
        text-align: center!important;
    }
}

.mobile-zero-padding {
    @media screen and (max-width:600px) {
        padding:0!important;
    }
}


@media screen and (max-width:500px) {
    @for $i from 1 to 21 {
        .w#{$i*5} {
            width:100%;
        }
    }
}