@mixin loopProperties($from, $to, $property, $className, $mul:1, $unit:px) {
    @for $i from $from to $to+1 {
        .#{$className}#{$mul*$i} {
            #{$property}:#{$mul*$i} + $unit;
        }
    }
}

@mixin mapProperties($map, $property, $className) {
    @each $key, $value in $map {
        .#{$className}-#{$key} {
            #{$property}:#{$value};
        }
    }
}

@mixin listProperties($list, $property, $className) {
    @each $value in $list {
        .#{$className}-#{$value} {
            #{$property}:#{$value};
        }
    }
}