@import "colors.scss";

%center-input {
    display:block;
    margin:15px auto;
}
%input {
    padding:8px;
    border:2px solid map-get($colors, "l-mid-grey");
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
    &:focus {
        border:2px solid map-get($colors, "secondary");
        outline:0;
    }
}
.input {
    @extend %input;
}
.center-input {
    @extend %input;
    @extend %center-input;
}
%btn {
    padding:8px;
    color:white;
    background-color:map-get($colors, "primary");
    border:1px solid map-get($colors, "primary-darker");
    border-radius: 5px;
    &:active {
        background-color:map-get($colors, "secondary");
        border:1px solid map-get($colors, "secondary-darker");
    }
    &:hover {
        cursor:pointer;
    }
}
.btn {
    @extend %btn;
}
.center-btn {
    @extend %btn;
    @extend %center-input;
}