nav {
    width:100%;
    height:80px;
    background-color: map-get($colors, "primary");
    position:sticky;
    top:0;
    z-index:100;

    ul {
        display:flex;
        justify-content: center;

        @media screen and (max-width:600px) {
            flex-direction: column;
            height:100%;
            text-align: center;
            align-items: center;
        }
    }
    li {
        list-style:none;
        height:80px;
        line-height:80px;

        @media screen and (max-width:600px) {
            width:200px;
            height:50px;
            line-height: 50px;
        }
    }
    a {
        text-decoration: none;
        color:white;
        padding:15px;
        font-family: 'Saira Semi Condensed', sans-serif;
    }

    @media screen and (max-width:600px) {
        height:100%;
    }
}
.nav-sticky {
    box-shadow:0px 3px 4px map-get($colors, "dark-grey");
}
.selected {
    border-bottom:3px solid map-get($colors, "secondary-lighter");
}
.upper-menu {
    height:30px;
    line-height: 30px;
    background-color: map-get($colors, "secondary");
    color:white;
    text-align: center;
}
header {
    background-image: url("../images/header.png");
    background-position: center;
    background-size: 100%;
    height:400px;
    background-attachment: fixed;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
}
.header-mini {
    height:200px;
}
.marketing {
    color:map-get($colors, "secondary-lighter");
    text-align: center;
    font-size: 30px;
}

.menu-icon {
    width:40px;
    height:40px;
    font-size: 40px;
    color:map-get($colors, "secondary");
    position:absolute;
}

@media screen and (max-width:1200px) {
    header {
        height:250px!important;
        background-attachment: scroll;
    }
}

@media screen and (max-width:800px) {
    header {
        height:200px!important;
    }
    .marketing {
        font-size:20px;
    }
}